<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="订单号:" label-width="70px">
					<el-input v-model="table.where.order_real_id" placeholder="请输入订单编号" clearable />
				</el-form-item>
				<el-form-item label="用户名:" label-width="80px">
					<el-input v-model="table.where.driver_name" placeholder="请输入用户名" clearable />
				</el-form-item>
				<el-form-item label="用户手机号:" label-width="90px">
					<el-input v-model="table.where.tel" placeholder="请输入用户手机号" clearable />
				</el-form-item>
				<el-form-item label="司机姓名:" label-width="90px">
					<el-input v-model="table.where.driver_name_" placeholder="请输入司机姓名" clearable />
				</el-form-item>
				<el-form-item label="司机手机号:" label-width="90px">
					<el-input v-model="table.where.driver_phone" placeholder="请输入司机手机号" clearable />
				</el-form-item>
				<el-form-item label="司机车牌号:" label-width="90px">
					<el-input v-model="table.where.car_number" placeholder="请输入司机车牌号" clearable />
				</el-form-item>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
						clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市'
						clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县市区:" label-width="62px" class="w-200">
					<el-select v-model="table.where.aid" placeholder='请选择县市区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<span style="margin-top: 7px;">日期：</span>
				<el-date-picker label="日期" :append-to-body='false' v-model="table.where.update_time"
					value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<el-form-item label="是否处理:" label-width="80px">
					<el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="100" />
						<el-option label="未处理" value="0" />
						<el-option label="处理中" value="1" />
						<el-option label="已处理" value="2" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button style="margin-left: 10px;" type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">
						搜索
					</el-button>
					<el-button @click="(table.where = { source: 2 }) && $refs.table.reload()">重置</el-button>
					<!-- <download v-if="permission.includes('sys:order:export')" style="margin-left: 0"
			  class="ele-btn-icon ml-20" label="导出" url="order/exportExcel" :params="exportOrderParams()" /> -->
				</el-form-item>
			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="180" />
					<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="200" />
					<el-table-column prop="driver_name" label="用户名" show-overflow-tooltip min-width="110" />
					<el-table-column prop="tel" label="电话" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_name_" label="司机姓名" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_phone" label="司机手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_number" label="司机车牌号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="content" label="投诉内容" show-overflow-tooltip min-width="140" />
					<el-table-column prop="create_time" label="投诉时间" show-overflow-tooltip min-width="120" />
					<!-- <el-table-column prop="order_price" label="订单金额(元)" show-overflow-tooltip min-width="120" />
			<el-table-column prop="driver_get_money" label="实际到账(元)" show-overflow-tooltip min-width="120" /> -->
					<el-table-column prop="status_name" label="处理状态" show-overflow-tooltip min-width="160">
					</el-table-column>
					<!--					<el-table-column prop="status_name" label="订单状态" show-overflow-tooltip min-width="120" />
			-->
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link v-if="row.admin_status == 1 && permission.includes('sys:service_order_car:view')"
								@click="handlecheck1(row)" icon="el-icon-view" type="primary" :underline="false">查看
							</el-link>
							<el-link v-if="row.admin_status == 0 && permission.includes('sys:service_order_car:edit')"
								@click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false">编辑
							</el-link>
							<!--							<el-link v-if="row.type == 1 && row.status!=2 " @click="handlecheck1(row)" icon="el-icon-view" type="primary" :underline="false">催办</el-link>-->
							<el-link
								v-if="row.type == 1 && row.status == 0 && permission.includes('sys:service_order_car:urge')"
								@click="handUrge(row)" icon="el-icon-view" type="primary" :underline="false">催办
							</el-link>
						</template>

					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>


		<!-- 查看订单详情 -->
		<el-dialog v-dialogDrag title="查看投诉详情" :visible.sync="dialogTableVisibleCheck" :destroy-on-close="true"
			:lock-scroll="false" @closed="closess()">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="140px" :ref="checkForm" :rules="editRules">

					<el-steps :space="200" :active="checkForm.status >= 2 ? 3 : checkForm.status" finish-status="success">
						<el-step title="未处理"></el-step>
						<el-step title="处理中"></el-step>
						<el-step title="已处理"></el-step>
					</el-steps>


					<el-form-item label="用户名：" prop="type" label-width='160px'>
						<el-input v-model="checkForm.driver_name" placeholder="" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="手机号：" prop="type" label-width='160px'>
						<el-input v-model="checkForm.tel" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="地区：" prop="set_area" label-width='160px'>
						<el-input v-model="checkForm.set_area" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉时间：" prop="update_time" label-width='160px'>
						<el-input v-model="checkForm.update_time" placeholder="请输入安卓更新地址" clearable class="w-400"
							disabled />
					</el-form-item>
					<el-form-item label="订单号：" prop="order_id" label-width='160px'>
						<el-input v-model="checkForm.order_id" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="司机姓名：" prop="driver_name_" label-width='160px'>
						<el-input v-model="checkForm.driver_name_" placeholder="" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="司机手机号：" prop="driver_phone" label-width='160px'>
						<el-input v-model="checkForm.driver_phone" placeholder="" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="司机车牌号：" prop="car_number" label-width='160px'>
						<el-input v-model="checkForm.car_number" placeholder="" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉类型：" prop="u_type" label-width='160px'>
						<el-input v-model="checkForm.u_type" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉内容：" prop="content" label-width='160px'>
						<el-input v-model="checkForm.content" type="textarea" placeholder="请输入安卓更新地址" clearable
							class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉图片：" prop="img" label-width='160px'>
						<!-- {{checkForm.imgList}} -->
						<div v-if=" checkForm.imgList !='' ">
							<el-image v-for="(option, index) in checkForm.imgList"     :key="index"
								style="width:100px;height:100px;margin-right:10px" :src="option" fit="cover"
								:preview-src-list="checkForm.imgList"></el-image>
						</div>
						<div v-else>
							暂无图片
						</div>
					</el-form-item>
					<el-form-item label="处理结果：" prop="admin_status" label-width='160px'>
						<el-radio-group v-model="checkForm.admin_status" @change="change()">
							<el-radio :label="0">未处理</el-radio>
							<el-radio :label="1">已处理</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="回复用户：" prop="admin_reply" label-width='160px'>
						<el-input v-model="checkForm.admin_reply" type="textarea" placeholder="请输入回复内容" clearable
							class="w-400" />
					</el-form-item>
					<el-form-item label="反馈商家（司机）：" prop="follow_message" label-width='160px'>
						<el-input v-model="checkForm.follow_message" type="textarea" placeholder="请输入反馈内容" clearable
							class="w-400" />
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer" v-if="showedit">
				<el-button @click="dialogTableVisibleCheck = false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>



		<!-- 查看订单详情 -->
		<el-dialog v-dialogDrag title="查看投诉详情" :visible.sync="dialogTableVisibleCheck1" :destroy-on-close="true"
			:lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="140px">
					<el-steps :space="200" :active="checkForm.status >= 2 ? 3 : checkForm.status" finish-status="success">
						<el-step title="未处理"></el-step>
						<el-step title="处理中"></el-step>
						<el-step title="已处理"></el-step>
					</el-steps>
					<el-form-item label="用户名：" prop="type" label-width='160px'>
						<el-input v-model="checkForm.driver_name" placeholder="" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="手机号：" prop="type" label-width='160px'>
						<el-input v-model="checkForm.tel" placeholder="请输入手机号" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="地区：" prop="set_area" label-width='160px'>
						<el-input v-model="checkForm.set_area" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="司机姓名：" prop="driver_name_" label-width='160px'>
						<el-input v-model="checkForm.driver_name_" placeholder="请输入地区" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="司机手机号：" prop="driver_phone" label-width='160px'>
						<el-input v-model="checkForm.driver_phone" placeholder="请输入司机手机号" clearable class="w-400"
							disabled />
					</el-form-item>
					<el-form-item label="司机车牌号：" prop="car_number" label-width='160px'>
						<el-input v-model="checkForm.car_number" placeholder="请输入司机车牌号" clearable class="w-400" disabled />
					</el-form-item>


					<el-form-item label="投诉时间：" prop="update_time" label-width='160px'>
						<el-input v-model="checkForm.update_time" placeholder="请输入安卓更新地址" clearable class="w-400"
							disabled />
					</el-form-item>
					<el-form-item label="订单号：" prop="order_id" label-width='160px'>
						<el-input v-model="checkForm.order_id" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉类型：" prop="u_type" label-width='160px'>
						<el-input v-model="checkForm.u_type" placeholder="请输入安卓更新地址" clearable class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉内容：" prop="content" label-width='160px'>
						<el-input v-model="checkForm.content" type="textarea" placeholder="请输入安卓更新地址" clearable
							class="w-400" disabled />
					</el-form-item>
					<el-form-item label="投诉图片：" prop="img" label-width='160px'>


						<template v-if="checkForm.imgList">
							<el-image v-for="(option, index) in checkForm.imgList" :key="index"
							style="width:100px;height:100px;margin-right:10px" :src="option" fit="cover"
							:preview-src-list="checkForm.imgList"></el-image>
						</template>
						<template v-else>暂无图片</template>


					</el-form-item>



					<!-- <el-form-item label="投诉图片:" class="drop-down-second aa d-flex" v-if="checkForm.imgList!=0">
        <el-image v-for="(option, index) in checkForm.imgList" :key="index"
                  style="width:100px;height:100px;margin-right:10px" :src="option" fit="cover"
                  :preview-src-list="checkForm.imgList"></el-image>
      </el-form-item> -->



					<el-form-item label="处理结果：" prop="admin_status" label-width='160px'>
						<el-radio-group :disabled="true" v-model="checkForm.admin_status" @change="change()">
							<el-radio :label="0">未处理</el-radio>
							<el-radio :label="1">已处理</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="回复用户：" prop="admin_reply" label-width='160px'>
						<el-input v-model="checkForm.admin_reply" type="textarea" placeholder="请输入回复内容" clearable
							class="w-400" disabled />
					</el-form-item>
					<el-form-item label="反馈商家（司机）：" prop="follow_message" label-width='160px'>
						<el-input v-model="checkForm.follow_message" type="textarea" placeholder="请输入反馈内容" clearable
							class="w-400" disabled />
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>
  <!--<link rel="stylesheet" href="https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css" />-->
  
<script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script>
  <script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script>
  <script>
  import download from '@/components/Download/index'
  import {
	mapGetters
  } from "vuex";
  
  export default {
	name: "SysUser",
	components: {
	  download
	},
	data() {
	  return {
		isPlay: true,
		tableCreate_time: '', //搜索选中时间戳
		tableend_time: '',
		bgWidth: '',
		aa: true,
		ampDisplay: false,
		dialogVisibleAdd: false,
		gStar: [0, 0, 0, 0, 0],
		gStartext: [false, false, false, false, false],
		charList: [],
		showedit: false,
		table: {
		  url: 'customer/order_complain_list',
		  where: {}
		}, // 表格配置
		choose: [], // 表格选中数据
		editForm: {}, // 表单数据
		checkForm: {},
		editRules: { // 表单验证规则
    admin_reply: [
      { required: true, message: '请输入回复内容', trigger: 'blur' },
      { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
    ],
    follow_message: [
      { required: true, message: '请输入反馈内容', trigger: 'blur' },
      { min: 1, max: 100, message: '长度在 1 到 100个字符', trigger: 'blur' }
    ]
    },
		dialogTableVisibleCheck: false,
		dialogTableVisibleCheck1: false,
		dialogTableVisibleChatRecord: false,
		provArr: [],
		time: [],
		cityArr: [],
		districtArr: [],
		audioArr: [],
		urgentList: [],
		imageUrl: false,
		headImg: '',
		zoom: 12,
		center: [104.07, 30.67],
		ampSrc: '',
		driverInfo: {},
		orderInfo: {},
		audio: '',
	  }
	},
	created() {
	  this.$http.get('/common/province_list').then(res => {
		var data = JSON.parse(res.data)
		this.provArr = data
	  })
  
	  var url = this.$route.query.orderId
	  if (url != undefined && url != '') {
		this.dialogTableVisibleChatRecord = true
		this.getChat(url)
	  } else {
		this.dialogTableVisibleChatRecord = false
	  }
	},
	computed: {
	  ...mapGetters(["permission"]),
	},
  
	mounted() {
	},
	methods: {
    //显示
	  handlecheck1(row) {
		this.showedit = true
    this.dialogTableVisibleCheck1 = true
	if(row.images){
		row.imgList = row.images.split(',')
	}
	this.imgList = row.imgList;
    this.checkForm = row;
    
	  },
	  change(val) {
		//alert(this.radio)
	  },
	  birthdayChange() {
		//var time = [];
  //				if(this.table.where.create_time.length >0){
		this.time[0] = this.tableCreate_time
		this.table.where.create_time = this.time;
  //				}else{
  //					(this.table.where.create_time).unshift(this.tableCreate_time)
  //				}
		//console.log(this.tableCreate_time)
		//console.log(this.table.where.create_time)
	  },
	  birthdayChange1() {
		//if(this.table.where.create_time.length >0){
		this.time[1] = this.tableend_time
		this.table.where.create_time = this.time;
		//console.log(this.table.where.create_time)
  
  //				}else{
  //					(this.table.where.create_time).push(this.tableend_time)
  //				}
  //				(this.table.where.create_time).push(this.tableend_time)
	  },
	  // 导出数据参数
	  exportOrderParams() {
		const query = this.table.where
		// const params = this.params
		const selectionIds = this.choose.map(item => {
		  return item.id
		})
  
		return {
		  ids: selectionIds,
		  ...query
		}
	  },
	  hanlePicture(src) {
		this.ampDisplay = true
		this.ampSrc = src
	  },
	  handleClose() {
		this.ampDisplay = false
	  },
	  closeDialog() {
		var middlePlay = document.querySelectorAll('.pasuVideo')
		for (let i = 0; i < middlePlay.length; i++) {
		  middlePlay[i].pause()
		  middlePlay[i].currentTime = 0
		}
	  },
	  handlePlay(url, id, index) {
		let that = this
		var middlePlay = document.querySelectorAll('.pasuVideo')
		middlePlay[0].pause()
		middlePlay[0].currentTime = 0
		var middle = 'middleindex'
		middlePlay = this.$refs[middle]
		middlePlay.play()
		that.isPlay = false
		middlePlay.addEventListener('ended', function () {
		  that.isPlay = true
		})
  
  
	  },
	  dropClick(command, row) {
		if (command === 'remove') { //删除
		  this.remove()
		} else if (command === 'chat') { //紧急联系人
		  this.handleChat(row)
		} else if (command === 'getChat') { //聊天记录
		  this.getChat(row)
		}
	  },
	  save() {
          //console.log('77788888888888888',this.checkForm.admin_reply);
   
          if (this.checkForm.admin_status != 1) {
            this.$message({
          	type: 'error',
          	message: '请选择已处理'
            });
            return false;
          }
          
          if (!this.checkForm.admin_reply) {
            this.$message({
          	type: 'error',
          	message: '请填回复内容'
            });
            return false;
          }
          
          if (!this.checkForm.follow_message) {
            this.$message({
          	type: 'error',
          	message: '请填写反馈内容'
            });
            return false;
          }
            
         
            
          this.$http.post('/customer/choose_complain', {
            id: this.checkForm.id,
            order_id: this.checkForm.order_id,
            follow_message: this.checkForm.follow_message,
            admin_reply: this.checkForm.admin_reply,
            aid: this.checkForm.aid,
            uid: this.checkForm.uid,
          }).then(res => {
            if (res.data.code === 0) {
          	this.$message({
          	  type: 'success',
          	  message: res.data.msg
          	});
          	this.dialogTableVisibleCheck = false
          	this.$refs.table.reload();
            } else {
          	this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
          
       
          
          
          
		
	  },
	  // 聊天记录
	  getChat(id) {
		const loading = this.$loading({
		  lock: true,
		  target: document.querySelector(".records")
		});
		this.$http.post('/order/chat_log', {
		  id: id
		}).then(res => {
		  var data = res.data
		  loading.close()
		  data.log.forEach(function (item) {
			item.isTimeShow = 1
			if (item.content.S == 21) {
			  item.isPlay = true
			  if (item.content.P.LONG == 1) {
				item.bgWidth = '8rem'
			  }
			  item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
			}
			if (item.content.S == 26) {
			  item.content.P.POINT = item.content.P.POINT.split(',')
			  //console.log(item.content.P.POINT)
  
			}
		  });
		  for (var i = 0; i < data.log.length; i++) {
			for (var j = i + 1; j < data.log.length - 1; j++) {
			  if (data.log[i].create_time == data.log[j].create_time) {
				data.log[j].isTimeShow = 0 //不显示时间
			  } else {
				data.log[j].isTimeShow = 1
			  }
			}
		  }
		  this.charList = data.log
		  this.driverInfo = data.driver
		  this.orderInfo = data.order_info
		})
  
	  },
	  handleChat(row) {
		this.dialogTableVisibleChatRecord = true
		this.getChat(row.id)
	  },
	  /***选择省**/
	  handleChangeProv(e) {
		/** 获取被选省份的pid**/
		var pid = ''
		this.provArr.forEach(function (item) {
		  if (item.pid == e) {
			pid = item.pid
		  }
		})
		/** 根据被选省份的pid获取省市下面的市**/
		this.$http.post('/common/city_list', {
		  pid: pid
		}).then(res => {
		  var data = JSON.parse(res.data)
		  this.cityArr = data
		  // //console.log(this.cityArr)
		  /** 选择省份清空市县**/
		  this.table.where.cid = ''
		  this.table.where.aid = ''
		})
	  },
	  /***选择市**/
	  handleChangeCity(e) {
		if (e) {
		  /** 获取被选市的cid**/
		  var cid = ''
		  this.cityArr.forEach(function (item) {
			if (item.cid == e) {
			  cid = item.cid
			}
		  })
		  /** 根据被选市的cid获取市下面的县**/
		  this.$http.post('/common/area_list', {
			cid: cid
		  }).then(res => {
			var data = JSON.parse(res.data)
			this.districtArr = data
			/** 选择省份清空县**/
			this.table.where.aid = ''
		  })
		} else {
		  this.table.where.pid = ''
		  this.table.where.aid = ''
		}
	  },
	  /***查看用户信息**/
	  handlecheck(row) {
		this.showedit = true
		this.dialogTableVisibleCheck = true
		row.imgList = row.images.split(',')
    this.checkForm = row;
    },
  
	  /*** 刪除(批量刪除)*/
	  remove(row) {
		if (!row) { // 批量删除
		  if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
		  let ids = this.choose.map(d => d.id);
		  this.$confirm('确定要删除选中的订单吗?', '提示', {
			type: 'warning'
		  }).then(() => {
			const loading = this.$loading({
			  lock: true
			});
			this.$http.post('/order/delete', {
			  id: ids
			}).then(res => {
			  loading.close();
			  if (res.data.code === 0) {
				this.$message({
				  type: 'success',
				  message: res.data.msg
				});
				this.$refs.table.reload();
			  } else {
				this.$message.error(res.data.msg);
			  }
			}).catch(e => {
			  loading.close();
			  this.$message.error(e.message);
			});
		  }).catch(() => 0);
		} else { // 单个删除
		  const loading = this.$loading({
			lock: true
		  });
		  this.$http.post('/order/delete', {
			id: row.id
		  }).then(res => {
			loading.close();
			if (res.data.code === 0) {
			  this.$message({
				type: 'success',
				message: res.data.msg
			  });
			  this.$refs.table.reload();
			} else {
			  this.$message.error(res.data.msg);
			}
		  }).catch(e => {
			loading.close();
			this.$message.error(e.message);
		  });
		}
	  },
	  /*** 拉入黑名单*/
	  order_status_update(row) {
		this.$confirm('确定完成此订单?', '提示', {
		  type: 'warning'
		}).then(() => {
		  const loading = this.$loading({
			lock: true
		  });
		  this.$http.post('/order/order_status_update', {
			id: row.id
		  }).then(res => {
			loading.close();
			if (res.data.code === 0) {
			  this.$message({
				type: 'success',
				message: res.data.msg
			  });
			  this.$refs.table.reload();
			} else {
			  this.$message.error(res.data.msg);
			}
		  }).catch(e => {
			loading.close();
			this.$message.error(e.message);
		  });
		}).catch(() => 0);
	  },
	  exportExcel() {
		let info = JSON.parse(JSON.stringify(this.table.where));
		//console.log('order data export');
		this.$http
			.get("/order/exportExcel", info)
			.then((res) => {
			  let data = res.data;
			  if (data.code == 0) {
				// 下载文件
				window.location.href = data.data;
				this.$message({
				  type: "success",
				  message: "导出成功",
				});
			  } else {
				this.$message.error("导出失败");
			  }
			})
	  },
	  // 催办
	  handUrge(row) {
		this.$confirm('确定催办吗?', '提示', {
		  type: 'warning'
		}).then(() => {
		  const loading = this.$loading({
			lock: true
		  });
		  this.$http.post('/customer/complain_urge', {
			id: row.id
		  }).then(res => {
			loading.close();
			if (res.data.code === 0) {
			  this.$message({
				type: 'success',
				message: res.data.msg
			  });
			  this.$refs.table.reload();
			} else {
			  this.$message.error(res.data.msg);
			}
		  }).catch(e => {
			loading.close();
			this.$message.error(e.message);
		  });
		})
	  },
	}
  }
  </script>
  
<style scoped>
.el-steps--horizontal {
	margin-left: 10%
}

.bgGreen {
	background: #89B929 !important;
	color: #fff;
}

.ele-block .el-upload-dragger {
	width: 100%;
}

.changeStyle {
	width: 150px;
	margin-right: 30px;
}

.el-upload-list el-upload-list--text {
	display: none !important
}

.el-form-item-label {
	width: 20%;
	text-align: left
}

.disCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mr-10 {
	margin-right: 10px;
}

.el-date-editor /deep/ .el-input,
.el-date-editor /deep/ .el-input__inner {
	width: 70% !important;
}

/deep/ .el-dialog {
	margin-top: 60px !important;
	max-width: 1200px;
}

.el-col {
	border-radius: 4px;
}

.bg-purple-dark {
	background: #99a9bf;
}

.bg-purple {
	background: #fff;
	height: 160px;
}

.bg-purple-light {
	background: #e5e9f2;
}

.grid-content {
	border-radius: 4px;
	min-height: 36px;
}

.row-bg {
	padding: 10px 0;
	background-color: #f9fafc;
}

.smsInner {
	border-bottom: 1px solid #ddd;
	padding-bottom: 14px;
}</style>
  